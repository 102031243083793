import React, { useState, useEffect, useRef } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import CustomTooltip from "main/components/Tooltip/Tooltip";
import dropDownStyles from "./select_drop_down.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
const { Option } = components;

const getColorStyles = ({
  showMultiRemoveValue = false,
  isMulti = false,
  menuMaxHeight = "300px",
  menuPosition = "absolute",
}) => {
  const colorStyles = {
    control: (provided, state) => ({
      ...provided,
      height: !isMulti ? "20px" : "auto", // Set the desired height here
      backgroundColor: "transparent !important",
      width: "100%",
      fontSize: "16px",
      fontWeight: 500,
      borderRadius: "8px",
      border: "1px solid #74b44a",
      padding: "0 0 0 2px !important",
      outline: "none",
      boxShadow: "none",
      "&:hover": {
        outline: "none",
        border: "1px solid #74b44a",
      },
      "&:focus": {
        outline: "none",
        border: "1px solid #74b44a",
      },
    }),
    valueContainer: (provided, state) => ({
      display: "flex",
      maxWidth: "360px",
      paddingLeft: "0px",
      marginLeft: "0px !important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      flexWrap: isMulti ? "wrap" : "nowrap",
      flex: "1 1 0%",
      position: "relative",
      overflowX: "hidden",
      alignItems: "center",
    }),
    input: (provided, state) => ({
      ...provided,
      display: "inline",
    }),
    option: (styles, props) => {
      return {
        background: "none",
        padding: "4px 8px",
        cursor: "pointer",
        borderBottom: "1px solid #e5e5e5",
        "&:hover": {
          background: "#f5f5f5",
        },
      };
    },
    singleValue: (provided) => ({
      ...provided,
      marginLeft: "0px",
      fontSize: "14px",
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        height: "30px",
        verticalAlign: "middle",
        backgroundColor: data?.color || "#e4fad3",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      display: showMultiRemoveValue ? "block" : "none",
      ":hover": {
        color: "black",
        cursor: "pointer",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "gray",
      };
    },
    clearIndicator: (styles) => {
      return {
        ...styles,
        cursor: "pointer",
        color: "black",
        ":hover": {
          color: "black",
          cursor: "pointer",
        },
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        position: menuPosition,
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        maxHeight: menuMaxHeight,
      };
    },
  };
  return colorStyles;
};

const SelectDropDown = ({
  placeholder = "Select ..",
  menuPlacement = "bottom",
  menuMaxHeight = "300px",
  menuPosition = "absolute",
  isMulti = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = false,
  defaultMenuIsOpen = false,
  isClearable = true,
  showMultiRemoveValue = false,
  isSearchable = false,
  searchValue = "",
  defaultValue = "",
  handleOnChange = () => {},
  onMenuOpen = () => {},
  onMenuClose = () => {},
  resetCacheKey = "1",
  loadOptions = () => {
    return { options: [], hasMore: false };
  },
  options = [],
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const containerRef = useRef(null);

  const handleOnMenuOpen = (e) => {
    setIsMenuOpen(true);
    onMenuOpen(e);
  };

  const handleOnMenuClose = (e) => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      onMenuClose(e);
    }
  };

  const handleScroll = (e) => {
    if (isMenuOpen) {
      const dropdownMenu = document.querySelector(".menuList");
      if (dropdownMenu && dropdownMenu.contains(e.target)) {
        // If the scroll target is inside the dropdown, do nothing
        return;
      }
      // Close the menu if scroll is outside the dropdown
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, true); // Detect mouse wheel scroll

    return () => {
      window.removeEventListener("wheel", handleScroll, true);
    };
  }, [isMenuOpen]);

  const validateIsSelected = (value) => {
    if (Array.isArray(searchValue)) {
      const isInArray = searchValue.find((val) => val?.value === value);
      return isInArray;
    } else {
      if (Number(searchValue?.value) === Number(value)) {
        return true;
      } else {
        return false;
      }
    }
  };
  const IconOption = (props) => {
    return (
      <Option {...props}>
        <div className="d-flex align-items-center justify-content-between">
          <span> {props.data.label}</span>
          <span>
            {validateIsSelected(props.data.value) ? (
              <>
                <FontAwesomeIcon
                  className={dropDownStyles.check_icon}
                  icon={faCheck}
                />
              </>
            ) : (
              <></>
            )}
          </span>
        </div>
      </Option>
    );
  };

  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props} className="menuList">
        {props.children}
      </components.MenuList>
    );
  };

  const MultiValueContainer = (props) => {
    return (
      <CustomTooltip
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        text={props?.data?.label}
      >
        <div className={dropDownStyles.multiValueContainer}>
          <components.MultiValueContainer {...props} />
        </div>
      </CustomTooltip>
    );
  };
  return (
    <div ref={containerRef}>
      <AsyncPaginate
        menuPlacement={menuPlacement}
        key={resetCacheKey}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        defaultMenuIsOpen={defaultMenuIsOpen}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={searchValue}
        onChange={handleOnChange}
        components={{
          Option: IconOption,
          MultiValueContainer,
          MenuList: CustomMenuList,
        }}
        loadOptions={loadOptions}
        styles={getColorStyles({
          showMultiRemoveValue,
          isMulti,
          menuMaxHeight,
          menuPosition,
        })}
        menuIsOpen={isMenuOpen}
        onMenuClose={handleOnMenuClose}
        onMenuOpen={handleOnMenuOpen}
        options={options}
      />
    </div>
  );
};

export default SelectDropDown;

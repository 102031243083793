import React from "react";
import styles from "./action_btn.module.css";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "main/shared/helpers";
import { publishReportDataAction, refreshReportDataAction, saveReportDataAction } from "store/actions/sample_strategy/sampleStrategyAction";
import { useParams } from "react-router-dom";

const ActionButton = () => {
  const { insp_id } = useParams();

  const dispatch = useDispatch();
  const sampleStrategyReport = useSelector(
    (state) => state.sampleStrategyReport
  );
  const { reportData, selectedRows } = sampleStrategyReport;

  const handleOnSave = async () => {
    try {
      await dispatch(saveReportDataAction(insp_id));
      showToast("Report Save Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  };

  const handleRefresh = async () => {
    try {
      await dispatch(refreshReportDataAction(insp_id));
      showToast("Data Refresh Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  };

  const handlePublish = async () => {
    try {
      if(selectedRows.length === 0) {
        showToast("Please select at least one row to publish");
        return;
      }
      await dispatch(publishReportDataAction(insp_id));
      showToast("Report Publish Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.action_buttons}>
        <Button onClick={handleOnSave} className={styles.save_btn} size="sm">
          Save
        </Button>
        <Button
          onClick={handleRefresh}
          className={styles.refresh_btn}
          size="sm"
        >
          Refresh
        </Button>
        <Button onClick={handlePublish} className={styles.publish_btn} size="sm">
          Publish
        </Button>
      </div>
    </div>
  );
};

export default ActionButton;
